@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,300;1,400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  overflow-x: hidden;
}

// .swiper-button-next,
// .swiper-button-prev {
//   color: white !important; /* Nouvelle couleur des boutons */
//   background: black !important;
//   opacity: 0.5 !important; /* Couleur de fond des boutons */
//   padding: 10px 15px !important; /* Espacement intérieur des boutons */
//   /* Forme circulaire des boutons */
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important; /* Ombre pour donner un effet 3D */
//   /* Taille de l'icône dans les boutons */
//   height: 50px !important; /* Hauteur des boutons */
//   width: 50px !important; /* Largeur des boutons */
//   top: 50% !important;
//   transform: translateY(-50%) !important;
// }

// .swiper-button-next {
//   right: 0 !important;
// }

// .swiper-button-prev {
//   left: 0 !important;
// }

// .swiper-button-next:hover,
// .swiper-button-prev:hover {
//   opacity: 0.6 !important; /* Changement de couleur de l'icône au survol */
//   transition: 0.3s !important; /* Transition douce lors du changement de couleur */
// }
